<template>
    <master-panel>
        <template v-slot:body>
            <loading-spinner line height="" v-if="checking"/>
            <v-row v-else-if="error">
                <v-col cols="12" class="title-section">
                    ¡Ha ocurrido un error!
                </v-col>
                <v-col cols="12" class="text-section">
                    El enlace ha expirado o ya fue utilizado. Si aún desea cambiar la contraseña realice una nueva
                    solicitud.
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" class="title-section">
                    Restablecer contraseña
                </v-col>
                <v-col cols="12" class="text-section">
                    A continuación escriba su nueva contraseña y confirme que la ha escrito bien en ambos campos.
                </v-col>
                <v-col cols="12">
                    <kimsa-password-field
                        label="Contraseña"
                        :rules="passwordRules"
                        @change="password = $event"
                        outlined dense
                        @enter="onEnterPassword"
                    />
                </v-col>
                <v-col cols="12">
                    <kimsa-password-field
                        label="Repetir Contraseña"
                        :rules="confirmPasswordRules"
                        @change="confirmPassword = $event"
                        outlined dense
                        @enter="confirm"
                        ref="confirmPassword"
                    />
                </v-col>
                <v-col cols="12" class="text-center">
                    <v-btn class="normal-btn" depressed color="primary" :disabled="disabled" @click="confirm"
                           :loading="loading">
                        Confirmar
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </master-panel>
</template>

<script>
import KimsaPasswordField from "@/components/common/inputs/KimsaPasswordField";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import httpService from "@/services/httpService";
import MasterPanel from "@/components/layouts/MasterPanel";
import {rules} from "@/utils/Rules";

export default {
    name: "ResetPassword",
    components: {MasterPanel, LoadingSpinner, KimsaPasswordField},
    data() {
        return {
            password: '',
            confirmPassword: '',
            checking: false,
            loading: false,
            error: false,
        }
    },
    computed: {
        disabled(vm = this) {
            return !vm.password || !vm.confirmPassword || vm.password !== vm.confirmPassword
        },
        token(vm = this) {
            return vm.$route.query?.token || null
        },
        passwordRules() {
            return [rules.required, rules.minLength(this.password, 6)]
        },
        confirmPasswordRules() {
            return [rules.required, rules.sameValue(this.confirmPassword, this.password, 'contraseña')]
        },
    },
    async mounted() {
        const vm = this
        await vm.checkToken()
    },
    methods: {
        async checkToken() {
            const vm = this
            vm.error = false
            if (!vm.token) return vm.error = true
            vm.checking = true
            let response = await httpService.post('auth/check-password-token', {token: vm.token})
            if (response.data.error) vm.error = true
            vm.checking = false
        },
        onEnterPassword() {
            const vm = this
            vm.$refs.confirmPassword.$refs.field.focus()
        },
        async confirm() {
            const vm = this
            vm.loading = true
            let response = await httpService.post('auth/change-password', {token: vm.token, password: vm.password})
            vm.toast(response.data.message)
            vm.loading = false
            await vm.$router.push({name: 'login', query: {email: response.data.email}})
        }
    }
}
</script>

<style scoped>

.title-section {
    font-size: 22px;
    line-height: 28px;
    font-weight: bold;
    text-align: center;
}

.text-section {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #707070;
}

</style>